<template>
  <div style="width: 100%">
    <div class="wine-data" style="width: 100%; max-width: 1344px">
      <div class="columns" v-if="inputmode">
        <div class="column is-11" v-if="showIpTypeSelect">
          <b-field label="Typ">
            <b-select v-model.number="ip.type" @input="setType()">
              <option
                v-for="option in $utilities.getList('invoicePositionTypes')"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </div>

        <!-- Rechnungsposition: Weinauswahl -->
        <div class="column is-11" v-if="showArticleSelect">
          <b-field class="column is-10">
            <b-autocomplete
              ref="winesearch"
              :data="articleList"
              placeholder="Wein auswählen"
              field="title"
              :loading="isFetching"
              @typing="getArticleData"
              @select="(option) => setArticle(option)"
              :autofocus="true"
            >
              <template slot-scope="props">
                <div class="media">
                  <div class="media-content">
                    {{ props.option.producer_name_de }}, {{ props.option.wine_name_de }},
                    {{ props.option.jahr }}, {{ props.option.flaschen_pro_einheit }}x{{
                      props.option.menge
                    }}L, W{{ props.option.wine_id }}-A{{ props.option.article_id }}
                  </div>
                </div>
              </template>
            </b-autocomplete>
          </b-field>
        </div>

        <!--- Auswahl der Lagerratikel -->
        <div class="column is-11" v-if="showStockArticleSelect">
          <b>
            {{ ip.producer_name_de }}, {{ ip.wine_name_de }}, {{ ip.jahr }},
            {{ ip.flaschen_pro_einheit }}x{{ ip.menge }}L</b
          >
          verfügbare Flaschen:
          <div
            class="columns margin-bottom0"
            v-for="(sa, k) in stockArticleList"
            :key="k"
          >
            <div class="column is-10">
              {{ sa.bottles }} {{ sa.bottles == 1 ? "Flasche" : "Flaschen" }} aus Ankauf
              {{ sa.consignment_id }} von {{ sa.name }}
              <span v-if="sa.info.length > 2">, Info: {{ sa.info }}</span>
              <small class="smallgrey2">
                <b
                  >EK-Preis {{ nf.format(sa.price_ek) }}€/Flasche, VK-Preis
                  {{ nf.format(sa.price_vk) }}€/Flasche,
                  <span class="has-text-danger" v-if="sa.from_private == 1">
                    differenzbesteuert, </span
                  >{{ sa.fine_wine == 1 ? "FineWine" : "Handelsware" }}</b
                >, Lager-ID {{ sa.id }} (Regal {{ sa.stockplace }})
                <span v-if="(sa.fine_wine = 1)">
                  <br />Label: {{ $utilities.getValue("label", sa.label) }}, Kapsel:
                  {{ $utilities.getValue("capsula", sa.capsula) }}, Füllstand:
                  {{ $utilities.getValue("fill_level", sa.fill_level) }}, Verpackung:
                  {{ $utilities.getValue("packaging", sa.packaging) }}
                </span>
              </small>
            </div>
            <div class="column is-2">
              <b-select
                v-model="selectBottles['s' + sa.id]"
                @input="setBottlesFromStock()"
                :disabled="
                  (localVatTypeForWine == 'diff' && sa.from_private == 0) ||
                  (localVatTypeForWine == 'normal' && sa.from_private == 1)
                "
              >
                <option
                  v-for="option in getNumbersOfStockBottles(
                    ip.flaschen_pro_einheit,
                    sa.bottles,
                    sa.id
                  )"
                  :value="option.value"
                  :key="option.value"
                >
                  {{ option.label }}
                </option>
              </b-select>
            </div>
          </div>
          <b-button
            icon-left="add_box"
            :disabled="ip.bottles <= 0"
            @click.prevent="finalizeIp()"
            >{{
              ip.bottles +
              (ip.bottles == 1 ? " Flasche" : " Flaschen") +
              "  hinzufügen (&empty; EK-Preis = " +
              ip.price_ek +
              "€)"
            }}</b-button
          >
        </div>

        <!--- Fertige Auswahl --->
        <div
          class="column is-8"
          v-if="
            ip.type != null &&
            showArticleSelect == false &&
            showIpTypeSelect == false &&
            showStockArticleSelect == false
          "
        >
          <b-field :label="ipLabel" v-if="ip.type != 'wine'">
            <b-input v-model="ip.title"></b-input>
          </b-field>
          <div v-if="ip.type == 'wine'" style="padding-left: 15px">
            {{ ip.producer_name_de }}, {{ ip.wine_name_de }}, {{ ip.jahr }},
            {{ ip.flaschen_pro_einheit }}x{{ ip.menge }}L

            <!--- Artikelnumemr immer anzeigen --->
            <span class="smallgrey2" v-if="invoiceType == 'invoice'">
              <b>
                W{{ ip.wine_id }}-A{{ ip.article_id }}
                <span v-if="invoiceType == 'invoice'"
                  >&empty; EK-Preis {{ nf.format(ip.price_ek) }}€/Flasche
                </span>
                <span class="has-text-danger">{{
                  ip.from_private == 1 ? ", differenzbesteuert " : ""
                }}</span> </b
              ><br />
              {{ getStockArticleDetails(ip.fromStockArticle) }}
            </span>
          </div>
        </div>

        <div
          class="column is-1"
          v-if="!showArticleSelect && !showIpTypeSelect && !showStockArticleSelect"
        >
          <label>Flaschen</label>
          <b-field v-if="ip.type == 'wine' && invoiceType == 'invoice'">
            {{ ip.bottles }}Fl.
          </b-field>

          <b-field v-if="ip.type == 'wine' && invoiceType == 'proforma'">
            <b-select v-model="ip.bottles">
              <option v-for="option in bottleOptions" :value="option" :key="option">
                {{ option }}Fl.
              </option>
            </b-select>
          </b-field>
        </div>

        <div
          class="column is-2"
          v-if="
            ip.type != null &&
            (ip.type != 'wine' || (ip.type == 'wine' && ip.wine_id != null)) &&
            !showStockArticleSelect
          "
        >
          <label>Betrag/Preis</label>
          <b-field>
            <b-field>
              <b-input
                @input="priceChanged"
                v-model="ip.price"
                custom-class="has-text-right"
              ></b-input>
              <p class="control">
                <span class="button is-static">€</span>
              </p>
            </b-field>
          </b-field>
        </div>

        <div class="column is-1">
          <b-field label="Löschen" v-if="inputmode">
            <b-button
              icon-right="delete_forever"
              @click.prevent="positionDelete(ip.key)"
              title="Löschen"
            >
            </b-button>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import delay from "lodash/delay";
import merge from "lodash/merge";
//import AddInvoicePositionDialog from "../_helpers/addinvoicepositiondialog.vue"
export default {
  name: "InvoicePosition",

  data() {
    return {
      nf: Intl.NumberFormat(),
      showIpTypeSelect: true,
      showArticleSelect: false,
      showStockArticleSelect: false,
      showIpDetails: false,
      ipLabel: "Position/Beschreibung",
      articleList: [],
      stockArticleList: [],
      isFetching: false,
      bottleOptions: this.getBottleOptions(),
      localVatTypeForWine: "",
      selectBottles: {},
      uniqueId:''
    };
  },

  props: {
    invoicePositionIndex: { type: [Number, String], required: true },
    ip: { type: Object, required: true },
    inputmode: { type: Boolean, required: true, default: true },
    invoiceType: { type: String, required: true, default: false },
    vatTypeForWine: { type: String, required: true, default: "-" },
  },

  mounted: function () {
    this.localVatTypeForWine = this.vatTypeForWine;
    this.uniqueId = Math.random().toString(16).slice(2);
    this.$root.$on('set-ups-rate', this.setUpsRate)
  },

  methods: {
    setType: function (value) {
      this.showIpTypeSelect = false;

      if (this.ip.type == "wine") {
        this.ipLabel = "Wein";
        this.showArticleSelect = true;
        delay(
          function (t = this) {
            t.$refs.winesearch.focus();
          },
          100,
          this
        );
      } else if (this.ip.type == "credit") {
        this.ipLabel = "Gutschrift";
        this.showIpDetails = true;
      } else if (this.ip.type == "ship") {
        this.ipLabel = "Versandkosten";
        this.showIpDetails = true;
        this.ip.title = "Versand und Verpackung ";
      } else if (this.ip.type == "upsship") {
        this.ipLabel = "Versandkosten";
        this.showIpDetails = true;
        this.ip.type = "ship";
        this.ip.title = "UPS-Versand und Verpackung ";
        this.ip.price = this.getUpsRate();

      } else if (this.ip.type == "misc") {
        this.ipLabel = "Sonstiges";
        this.showIpDetails = true;
      }
      this.recalcInvoice();
    },
    getArticleData: debounce(function (searchphrase) {
      if (searchphrase.length < 3) {
        this.data = [];
        return;
      }
      var t = this;
      this.isFetching = true;
      this.$http
        .post("/invoices/get-articles", {
          search: searchphrase,
          perpage: 100,
          invoiceType: this.invoiceType,
          vatType: this.vatTypeForWine,
        })
        .then(function (resp) {
          //console.log("done");
          t.articleList = resp.data.data;
          t.isFetching = false;
        })
        .catch((error) => {
          t.data = [];
          throw error;
        })
        .finally(() => {
          t.isFetching = false;
        });
    }, 200),

    positionDelete: function (pos) {
      //console.log("Winefield " + pos);
      //console.log(this.ip);
      var t = this;
      /*
      t.ip.type = "deleted";
      t.ip.price = 0;
      t.ip.price_ek = 0;
      t.ip.auction_id = null;
      t.ip.deleted = 1;
      */

      t.$emit("position-delete", pos);
      t.recalcInvoice();
      //this.$destroy();
      //this.$el.parentNode.removeChild(this.$el);
    },

    setArticle: debounce(function (value) {
      var t = this;

      //wenn richtige Rechnung, dann  Verfügbare Lagerartikel laden
      if (t.invoiceType == "invoice") {
        t.ip = merge(t.ip, value);
        t.ip.bottles = 0;
        t.ip.price_ek = 0;
        t.ip.vat = t.$store.getters.config.vat;

        t.ip.title =
          value.producer_name_de +
          ", " +
          value.wine_name_de +
          " " +
          value.jahr +
          ", " +
          value.flaschen_pro_einheit +
          "x" +
          value.menge +
          "L";
        t.ip.fromStockArticle = [];
        t.ip.from_private = 0;
        this.isFetching = true;
        this.$http
          .post("/invoices/get-stock-articles", {
            article_id: value.article_id,
            invoiceType: this.invoiceType,
            vatType: this.vatTypeForWine,
          })
          .then(function (resp) {
            //console.log("done");
            t.stockArticleList = resp.data.data;
            t.showArticleSelect = false;
            t.showStockArticleSelect = true;
            t.isFetching = false;

            for (var i = 0; i < t.stockArticleList.length; i++) {
              t.selectBottles["s" + t.stockArticleList[i].id] = 0;
              t.ip.fromStockArticle.push({
                stockarticle_id: t.stockArticleList[i].id,
                bottles: 0,
                price_ek: t.stockArticleList[i].price_ek,
              });
            }
          })
          .catch((error) => {
            t.data = [];
            throw error;
          })
          .finally(() => {
            t.isFetching = false;
          });
      }
      // wenn Pro-Forma-Rechnung, dann Artikel auf REchnung setzen
      else {
        //console.log(value);
        t.ip = merge(t.ip, value);
        if (t.invoiceType == "invoice") {
          t.ip.price = value.price_vk * value.bottles;
        } else {
          t.ip.price = 10;
        }

        if (t.invoiceType == "proforma") {
          t.ip.bottles = 1;
        }

        t.ip.vat = t.$store.getters.config.vat;
        t.ip.title =
          value.producer_name_de +
          ", " +
          value.wine_name_de +
          " " +
          value.jahr +
          ", " +
          value.flaschen_pro_einheit +
          "x" +
          value.menge +
          "L";
        t.ip.bottles = value.bottles;

        t.$emit("position-data-update", t.ip.index, t.ip);
        t.showArticleSelect = false;

        //console.log("done");
        t.recalcInvoice();
      }
    }, 50),

    priceChanged: function () {
      //console.log("Price changed");
      var price = String( this.ip.price);
      price = price.replace(",", ".");
      if (price.substr(0, 1) != "-" || price.length >= 2) {
        this.ip.price = parseFloat(price);
      }
      this.ip.profit = 0;
      this.ip.profit = this.ip.price - this.ip.price_ek * this.ip.bottles;
      //console.log("Einname " + this.ip.price);
      //console.log("einkaufspreis " + this.ip.price_ek * this.ip.bottles);
      //console.log("Bruttoprofit " + this.ip.profit);

      //Profit berechnen
      if (this.ip.from_private == 1 && !isNaN(this.ip.price)) {
        //console.log("Diffsteuer beachtren");
        this.ip.profit = this.ip.profit / (1 + this.$store.getters.config.vat / 100);
        //console.log(this.ip.profit);
      }
      this.recalcInvoice();
    },

    recalcInvoice: function () {
      this.$parent.recalcInvoice();
    },
    getBottleOptions: function () {
      var tmp = [];
      for (var i = 1; i <= 60; i++) {
        tmp.push(i);
      }
      return tmp;
    },
    getNumbersOfStockBottles(flaschen_pro_einheit, bottles) {
      var rest = bottles % flaschen_pro_einheit;
      var c = 0;
      var options = [];

      while (c <= bottles) {
        options.push({ value: c, label: c + (c == 1 ? " Flasche" : " Flaschen") });
        c += flaschen_pro_einheit;
      }
      if (rest !== 0) {
        c += rest;
        options.push({ value: c, label: c + (c == 1 ? " Flasche" : " Flaschen") });
      }
      return options;
    },
    setBottlesFromStock() {
      this.ip.bottles = 0;
      this.ip.price_ek = 0;
      var firstStockIdWithBottles = false;
      var k = "";

      //Aray mit verfügbaren Weinen durchlaufen und
      for (var i = 0; i < this.ip.fromStockArticle.length; i++) {
        //Flaschenzahl auf Zahlen aus Select-Feldern setzen
        k = "s" + this.ip.fromStockArticle[i].stockarticle_id;
        this.ip.fromStockArticle[i].bottles = this.selectBottles[k];
        this.selectBottles[k] = 9999;
        this.selectBottles[k] = this.ip.fromStockArticle[i].bottles;

        //Flaschenzahl für Rechnungsposition erhöhen
        this.ip.bottles += this.ip.fromStockArticle[i].bottles;
        //Einkaufspreis für Rechnungsposition hinzufügen
        this.ip.price_ek +=
          this.ip.fromStockArticle[i].price_ek * this.ip.fromStockArticle[i].bottles;

        if (
          this.ip.fromStockArticle[i].bottles >= 1 &&
          firstStockIdWithBottles == false
        ) {
          firstStockIdWithBottles = this.ip.fromStockArticle[i].stockarticle_id;
        }
      }
      //Summierten einkaufspreis durch rechnungsanzahl teilen
      this.ip.price_ek = Math.round((this.ip.price_ek * 100) / this.ip.bottles) / 100;

      //console.log("first " + firstStockIdWithBottles);
      if (firstStockIdWithBottles == false) {
        this.setLocalForWine(0);
      } else {
        for (var i = 0; i < this.stockArticleList.length; i++) {
          //console.log("iterate " + this.stockArticleList[i].id);
          if (this.stockArticleList[i].id == firstStockIdWithBottles) {
            //console.log("found" + firstStockIdWithBottles);
            this.ip.from_private = this.stockArticleList[i].from_private;

            this.setLocalVatTypeForWine(this.stockArticleList[i].from_private);

            break;
          }
        }
      }
      this.recalcInvoice();
      this.$emit("set-vat-type-for-wine");
    },
    setLocalVatTypeForWine(from_private) {
      //console.log(from_private);
      if (this.ip.bottles >= 1 && this.vatTypeForWine == "all") {
        this.localVatTypeForWine = from_private == 1 ? "diff" : "normal";
      } else {
        this.localVatTypeForWine = this.vatTypeForWine;
      }
    },

    finalizeIp() {
      this.showStockArticleSelect = false;
      this.$emit("set-vat-type-for-wine");
      this.recalcInvoice();
    },
    getStockArticleDetails(sa) {
      var d = [];
      for (var i = 0; i < sa.length; i++) {
        if (sa[i].bottles >= 1) {
          d.push(
            "Lager-ID " +
              sa[i].stockarticle_id +
              ": " +
              sa[i].bottles +
              " Fl. je " +
              this.nf.format(sa[i].price_ek) +
              "€"
          );
        }
      }

      return d.join(", ");
    },
    getUpsRate () {
      this.$emit('get-ups-rate', this.uniqueId);
    },
    setUpsRate (id, rate)
    {
      if (this.ip.type == 'ship' && id == this.uniqueId)
      {
        //console.log(id, rate)
        this.ip.price = rate;
        this.priceChanged();
      }
      
    }
  },
};
</script>

<style>
div.deletelink span.icon {
  color: rgb(230, 20, 20);
}

div.deletelink button.button {
  border-color: rgb(230, 20, 20);
}
</style>
